.Step2 {
  padding-bottom: 80px;
}

.vinInfo {
  max-width: 555px;
  margin: 0 auto;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  &__header {
    padding: 40px 0 12px;
    display: flex;
  }
  &__content {
    margin-bottom: 32px;
  }
  &__footer-text {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: var(--font-lexus-book);
    margin-bottom: 24px;
    border-top: 1px solid #e2e8ef;
    border-bottom: 1px solid #e2e8ef;
    padding-top: 32px;
    padding-bottom: 32px;
    color: #101010;
    font-weight: 400;
  }
  &__footer-dynamicBlock {
    font-size: 13px;
    line-height: 20px;
    font-family: var(--font-lexus-book) !important;
    margin-bottom: 24px;
    color: #101010;
    font-weight: 400;

    h1 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 48px;
    }
    h2 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    h3 {
      font-size: 12px;
      line-height: 19px;
      margin-bottom: 16px;
    }
    p {
      margin-bottom: 48px;
      color: #101010;
      font-family: var(--font-lexus-book) !important;
    }
    a {
      cursor: pointer;
      text-decoration: underline;
      color: #101010;
      &:hover {
        text-decoration: none;
      }
    }
    ul {
      padding-left: 44px;
      margin-bottom: 48px;
      li {
        font-size: 16px;
        line-height: 24px;
        position: relative;
        margin-bottom: 8px;
        color: #101010;
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: -24px;
          top: 11px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: var(--primary-red);
        }
      }
    }
    ol {
      margin-bottom: 48px;
      counter-reset: item;
      li {
        font-size: 16px;
        line-height: 24px;
        position: relative;
        margin-bottom: 8px;
        list-style-type: 1;
        counter-increment: step-counter;
        color: #101010;
        &::before {
          content: counter(step-counter) '. ';
          color: var(--primary-red);
          font-weight: 700;
        }
      }
    }
    b {
      font-weight: 700;
    }
    em {
      font-style: italic;
    }
  }
}
.vin {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  &__info {
    font-size: 22px;
    line-height: 30px;
    color: var(--black);
    p:nth-of-type(1) {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.5px;
      color: #6c6f76;
      margin-bottom: 5px;
    }
    p:nth-of-type(2) {
      font-size: 18px;
      color: #1a1a1f;
    }
    @media (max-width: 567px) {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
  &__btn {
    text-transform: uppercase;
    font-weight: 700;
    font-family: var(--font-lexus-book);
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 20px;
    color: #20365c;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    svg {
      margin-left: 9px;
    }
    @media (max-width: 567px) {
      width: 100%;
      justify-content: flex-start;
    }
  }
}
.info-list {
  margin-bottom: 32px;
  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    &:last-child {
      border-bottom: 1px solid var(--gray-4);
      padding-bottom: 32px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__row-td {
    display: flex;
    align-items: center;
    .td-fildName {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.5px;
      color: #6c6f76;
      margin-right: 12px;
      margin-bottom: 4px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    .td-value {
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 1px;
      color: #1a1a1f;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}
.warrantyResult {
  display: flex;
  border-bottom: 1px solid var(--gray-4);
  &__icon {
    position: relative;
    width: 32px;
    height: 32px;
    // background-color: var(--primary-black);
    border-radius: 50%;
    margin-right: 18px;
    &.valid {
      background-color: #4CAF50;
      &:after {
        position: absolute;
        content: '';
        border: solid #fff;
        border-width: 0 1.2px 1.2px 0;
        height: 12px;
        left: calc(50% - 3px);
        top: calc(50% - 7px);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 6px;
      }
    }
    &.invalid {
      background-color: #B12A31;
      &:after {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        height: 1px;
        width: 12px;
        background-color: #fff;
      }
      &:before {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        height: 1px;
        width: 12px;
        background-color: #fff;
      }
    }
  }
  &__text {
    width: calc(100% - 50px);
    p {
      font-family: var(--font-lexus-book);
      font-size: 16px;
      color: var(--primary-black);
      line-height: 20px;
      letter-spacing: 1px;
      color: #1a1a1f;
      &:nth-of-type(1) {
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        font-weight: 700;
      }
      &:last-child {
        margin-bottom: 10px;
      }
      &.warrantyResult__text-descr {
        margin-top: 20px;
        font-family: var(--font-lexus-book);
        text-align: justify;
        text-transform: none;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.8px;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            margin-right: 16px;
            position: relative;
            list-style: none;
            width: calc(100% / 2 - 16px);
            padding-left: 20px;
            line-height: 24px;
            font-size: 13px;
            margin-bottom: 16px;
            @media (max-width: 992px) {
              width: calc(100% / 2 - 16px);
              margin-right: 16px;
            }
            &::before {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              top: 11px;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: var(--primary-red);
            }
            &:last-child {
              margin-right: 0;
            }
            @media (max-width: 767px) {
              width: 100%;
              margin-right: 0;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
