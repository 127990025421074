.chatBot {
  margin-top: 40px;
  @media (max-width: 767px) {
    padding-bottom: 25px;
  }
}
.tabs {
  &__title {
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-lexus-book);
    margin-bottom: 15px;
  }
  &__label-text {
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-family: var(--font-lexus-book);
    color: #101010 !important;
  }
}
.tabPanel {
  &__qr-code {
    border: 1px solid #e4e4e4;
    padding: 10px;
    width: 130px;
    height: 130px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    margin-bottom: 16px;
  }
  &__qr-code-text {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: #6c6f76;
    max-width: 299px;
    font-family: var(--font-lexus-book);
  }
}
.tabPanelList {
  margin-top: 40px;
  @media (max-width: 991px) {
    display: none;
  }
}
.socialTabs {
  flex-direction: column;
  align-items: center;
}
