.footer {
  border-top: 1px solid var(--gray-4);
  padding-top: 24px;
  margin-bottom: 80px;
  p {
    font-family: var(--font-lexus-book);
    font-size: 12px;
    line-height: 18px;
    color: var(--gray-platinum-dark);
    margin-bottom: 8px;
  }
  a {
    color: var(--gray-platinum-dark);
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
