.mapNavigation {
  width: 375px;
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 100;
  background-color: #fff;
  padding: 24px;
  padding-top: 13px;
  padding-bottom: 0;
  &.showResult {
    height: 93px;
    .mapNavigation__row:nth-of-type(2),
    .mapNavigation__row:nth-of-type(3) {
      display: none;
    }
  }
  &__row {
    border-bottom: 1px solid #e4e4e4;
    padding: 25px 0;
    &:last-child {
      border-bottom: none;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    top: 0;
    left: 0;
  }
}

.locationBtn {
  padding-left: 28px;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-black);
  position: relative;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &__icon {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
  }
}
.p0 {
  padding: 0;
}
.pb9 {
  padding-bottom: 9px;
}
