* {
  font-family: var(--font-lexus-book);
  // color: var(--primary-black);
  letter-spacing: 1px;
  box-sizing: border-box;
}
h1 {
  color: #191a1e;
  font-size: 40px;
  text-transform: uppercase;
  font-family: var(--font-lexus-book);
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 36px;
  }
}
h2 {
  color: #191a1e;
  font-size: 28px;
  font-weight: 400;
  font-family: var(--font-lexus-book);
  text-transform: uppercase;
  line-height: 30px;
  letter-spacing: 1.5px;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 28px;
  }
}
h3 {
  font-size: 13px;
  line-height: 20px;
  font-family: var(--font-lexus-book);
  color: var(--primary-black);
  margin-bottom: 25px;
  text-transform: uppercase;
}
.row {
  margin-left: -32px;
  margin-right: -32px;
  @media (max-width: 1199px) {
    margin-left: 0;
    margin-right: 0;
  }
  // @media (max-width: 767px) {
  //   margin-left: 0;
  //   margin-right: 0;
  // }
}
.container {
  max-width: 1280px;
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px;
  @media (max-width: 767px) {
    padding-right: 16px;
    padding-left: 16px;
  }
}
.opt {
  display: flex;
  justify-content: space-between;
}
.select__single-value {
  .opt {
    justify-content: flex-start;
    &__city {
      margin-left: 5px;
    }
  }
}
