.mapWrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 202px);
  min-height: 700px;
  &::before {
    content: "";
    display: block;
    padding-bottom: calc(56% - 202px);
    @media (max-width: 768px) {
      padding-bottom: calc(56% + 249px);
    }
  }
  & > div:first-child {
    z-index: 1;
    position: absolute!important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%!important;
    @media (max-width: 768px) {
      top: 247px;
      height: calc(100% - 249px)!important;
    }
  }
}
.map-container {
  position: relative;
  z-index: 1;
}
