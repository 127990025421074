.react-datepicker__triangle {
  transform: translate(225px, 0px) !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: var(--main-color);
}
.react-datepicker__day--selected {
  background-color: var(--main-color);
}
.react-datepicker-popper {
  width: 100%;
  z-index: 10 !important;
}
.react-datepicker {
  width: 100%;
  font-family: var(--font-lexus-book);
  &__triangle {
    display: none;
  }
  &__header {
    background-color: #fff !important;
    font-family: var(--font-lexus-book);
    // border-color: #c7cfd7;
    border-bottom: 1px solid #c7cfd7 !important;
    &--time {
      padding: 33px 0 !important;
      margin: 0 15px 15px;
      line-height: normal;
    }
  }
  &__time-list-item--disabled {
    display: none !important;
  }
  &__time-list-item {
    font-family: var(--font-lexus-book);
    border-radius: 24px;
    margin: 0 10px;
    padding: 3px 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    &--selected {
      background-color: #1a1a1f !important;
    }
  }
  &__month-container {
    width: calc(100% - 85px);
    padding: 0 24px;
  }
  &__day-names {
    display: flex;
    justify-content: space-between;
  }
  &__week {
    font-family: var(--font-lexus-book);
    display: flex;
    justify-content: space-between;
  }
  &__day-name {
    font-size: 14px !important;
    font-family: var(--font-lexus-book) !important;
    color: var(--gray-platinum-dark) !important;
  }
  &__navigation-icon::before {
    border-width: 1.5px 1.5px 0 0 !important;
    height: 5px !important;
    width: 5px !important;
    border-color: #1a1a1f !important;
  }
  &__current-month {
    padding: 16px 0;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &__navigation {
    top: 18px !important;
  }
  &__day {
    font-size: 14px;
    font-family: var(--font-lexus-book) !important;
    color: #1a1a1f !important;
    border-radius: 50% !important;
    width: 2rem !important;
    line-height: 2rem !important;
    &:hover {
      background-color: #b0b8c0 !important;
      cursor: pointer;
    }
  }
  &__day--disabled {
    color: #b6b6c0 !important;
    &:hover {
      background-color: transparent!important;
    }
  }
  &__day--today {
    background-color: #b0b8c0!important;
    color: #000 !important;
    font-weight: 400!important;
    &:hover {
      background-color: #b0b8c0!important;
    }
  }
  &__day--selected,
  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected,
  &__year-text--keyboard-selected {
    border-radius: 50% !important;
    background-color: #1a1a1f !important;
    color: #fff !important;
  }
  &__input-container {
    padding: 18px 18px 17px;

    height: 55px;
    overflow: hidden;
    input {
      background: transparent;
    }
  }
}
.react-datepicker-wrapper {
  position: relative;
  z-index: 2;
  width: 100%;
}

@media (max-width: 567px) {
  .react-datepicker {
    display: flex !important;
    flex-direction: column;
    &__month-container {
      width: 100%;
    }
    &__time-container {
      width: 100% !important;
    }
    &__header--time {
      padding: 10px !important;
    }
    &__time-box {
      width: 100% !important;
      padding-bottom: 10px;
    }
    &__time-list {
      display: flex;
      flex-wrap: wrap;
      height: auto !important;
    }
  }
}
