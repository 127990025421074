.tabs {
  &__tab {
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: none !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    font-family: var(--font-lexus-book) !important;
    color: var(--primary-black) !important;
    letter-spacing: 1px !important;
    &.Mui-selected {
      color: var(--primary-black) !important;
    }
  }
  &__panel {
    width: 100%;
  }
}
// .sectionData {
//   &__content {
//   }
//   &__content-body {
//   }
//   &__item {
//     display: flex;
//     justify-content: space-between;
//   }
//   &__col {
//     margin-bottom: 16px;
//     display: flex;
//     align-items: center;
//     &.label {
//       font-size: 13px;
//       line-height: 20px;
//       margin-bottom: 8px;
//       opacity: 0.8;
//     }
//     &.value {
//       font-size: 16px;
//       line-height: 24px;
//       text-align: right;
//     }
//   }
//   &__content-footer {
//     font-size: 13px;
//     line-height: 20px;
//     color: var(--black);
//     opacity: 0.5;
//   }
//   &.diagnostic {
//     .label {
//       max-width: 50%;
//     }
//     &.sectionData__item {
//       &:first-child {
//         border-bottom: 1px solid #e4e4e4;
//         border-top: none;
//         .label,
//         .value {
//           font-size: 16px;
//           color: var(--primary-black);
//         }
//         .value {
//           text-align: right;
//         }
//       }
//     }
//   }
// }

.sectionData {
  width: 100%;
  &__content {
    max-height: 385px;
    overflow-y: scroll;
    overflow-x: hidden;
    /* width */
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--white);
      margin-top: 58px;
      margin-bottom: 58px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1a1a1f;
    }
  }
  &__content-body {
    margin-bottom: 10px;
    padding-right: 8px;
  }
  &__content-footer {
    font-size: 16px;
    color: var(--primary-black);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    color: #101010;
  }
  &__item {
    padding: 13px 0;
    font-size: 16px;
    color: var(--primary-black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:first-child {
      padding-top: 0;
    }
  }
  &__col {
    &.value {
      color: var(--primary-black);
      // font-size: 18px;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }
  &.required {
  }
  &.diagnostic {
    .sectionData__item {
      .label {
        max-width: 50%;
        width: 50%;
        font-size: 16px;
      }
      .value {
        width: 50%;
        text-align: center;
        font-size: 12px;
      }
      &:first-child {
        border-bottom: 1px solid #e4e4e4;
        border-top: none;
        .label,
        .value {
          font-size: 16px;
          color: var(--primary-black);
        }
        .value {
          text-align: center;
        }
      }
      &:last-child {
        border-bottom: none;
        border-top: none;
        margin-top: 0;
        padding-top: 13px;
      }
    }
  }
  &.regulatory,
  &.materials {
    .sectionData__item {
      .label {
        width: 50%;
        // max-width: 33%;
      }
      .value {
        text-align: center;
        width: 25%;
        color: var(--primary-black);
        font-size: 13px;
      }
      .price {
        text-align: center;
        width: 25%;
        color: var(--primary-black);
        font-size: 16px;
      }
      &:first-child {
        // border-bottom: 1px solid #e4e4e4;
        border-top: none;
        .label,
        .value,
        .price {
          font-size: 16px;
          font-family: var(--font-toyota-semibold);
          color: var(--primary-black);
        }
        .value {
          text-align: center;
        }
      }
      &:last-child {
        border-bottom: none;
        border-top: none;
        margin-top: 0;
        padding-top: 13px;
      }
    }
  }
}
