.markerWrap {
  top: -40px !important;
  z-index: 1;
  &.active {
    z-index: 100 !important;
  }
}
.marker {
  position: relative;
  border: none;
  background: transparent;
  height: 55px;
  width: 32px;
  z-index: 10;
  &:hover {
    cursor: pointer !important;
  }
  &__text {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
  }
  &__icon {
    height: 55px;
    width: 32px;
    position: absolute;
    left: 0;
    top: 0;
  }
  &.active {
    .marker__text {
      color: var(--white);
    }
  }
}
.modalMarker {
  display: none;
  position: absolute;
  width: 375px;
  right: -385px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--white);
  &__content {
    // max-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--white);
      margin-top: 25px;
      margin-bottom: 25px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1a1a1f;
    }
  }
  &__close-btn {
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: var(--primary-black);
    color: white;
    font-size: 12px;
    border-radius: 50%;
    opacity: 0.9;
    cursor: pointer;
    svg {
      width: 5px;
      height: 5px;
    }

    &:active {
      opacity: 0.8;
    }
  }
  &.show {
    display: block;
  }
  &__item {
    padding: 24px;
    padding-right: 22px;
    margin-bottom: 19px;
    border-bottom: 1px solid #e4e4e4;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  &__item-row {
    margin-bottom: 16px;
    padding-left: 48px;
    &:last-child {
      margin-bottom: 0;
    }
    &.btnContainer {
      margin-bottom: 19px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 14px 15px;
        width: 100%;
        border-radius: 3px;
        letter-spacing: 1px;
        font-weight: 700;
        font-family: var(--font-lexus-book);
      }
    }
  }
  &__contacts {
    display: block;
  }
  &__contacts-title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: #1a1a1f;
    font-family: var(--font-lexus-book);
    font-weight: 700;
    letter-spacing: 1px;
  }
  &__contacts-line {
    margin-bottom: 16px;
    span {
      &:nth-of-type(1) {
        margin-bottom: 5px;
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #4d5e7d;
        margin-bottom: 4px;
      }
      &:nth-of-type(2) {
        display: block;
        font-size: 18px;
        line-height: 30px;
        color: #1a1a1f;
        font-family: var(--font-lexus-book);
        a {
          color: #1a1a1f;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.dealer {
  display: flex;
  padding-left: 0;
  font-family: var(--font-lexus-book);
  margin-bottom: 18px;
  &__number {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    // border: 1px solid var(--gray-4);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1a1a1f;
    color: #fff;
    margin-right: 18px;
    font-weight: 700;
    font-family: var(--font-lexus-book);
    font-size: 14px;
    letter-spacing: normal;
  }
  &__distance {
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-7);
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
  &__name {
    margin-bottom: 16px;
    font-family: var(--font-lexus-book);
    color: var(--primary-black);
    font-size: 18px;
    // line-height: 24px;
    letter-spacing: 1px;
  }
}
