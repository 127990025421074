.header {
  box-shadow: 0px 1px 1px rgba(177, 177, 177, 0.25);
  padding-top: 43px;
  padding-bottom: 24px;
  @media (max-width: 567px) {
    padding-bottom: 30px;
  }
}
.steps {
  display: flex;
  justify-content: center;
  padding-top: 53px;
  &__list {
    display: flex;
  }
  &__list-item {
    position: relative;
    margin-right: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    border-radius: 100px;
    padding: 6px 12px;
    &:after {
      content: '';
      width: 16px;
      height: 1px;
      background-color: #c7cfd7;
      position: absolute;
      right: -34px;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 767px) {
        right: -23px;
      }
    }
    @media (max-width: 767px) {
      margin-right: 30px;
    }
    &:hover {
      cursor: pointer;
      .label {
        color: var(--maig-black);
      }
      .number {
        color: var(--maig-black);
      }
    }
    &:last-child {
      margin-right: 0;
      &:after {
        display: none;
      }
    }
    &.active {
      background-color: #20365c;
      .number {
        color: var(--white);
      }
      .label {
        color: var(--white);
      }
    }
    .number {
      font-size: 14px;
      color: #4d5e7d;
      margin-right: 10px;
      font-family: var(--font-noah);
      @media (max-width: 767px) {
        margin-right: 0;
      }
    }
    .label {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1px;
      color: #4d5e7d;
      font-family: var(--font-noah);
      text-transform: uppercase;
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}
