:root {
  /* fonts */
  --font-lexus-book: 'NobelWGL-Book', sans-serif;
  --font-noah: 'noah-regular', sans-serif;

  --white: #ffffff;
  --black: #000000;
  /* 
  
   */
  --highlight-black: #15151b;
  --primary-red: #b12a31;
  --effective-blue: #0072f0;
  --blue: #20365c;

  --gray-1: #969ca3;
  --gray-100: #969ca3;
  --primary-black: #101010;
  --bg-light-gray: #f4f6fb;
  --gray-platinum-dark: #52565e;
  --input-border-color: #c7cfd7;

  --gray-3: #8b8c90;
  --gray-4: #e4e4e4;
  --gray-5: #cecfd0;
  --gray-6: #eff0f0;
  --gray-7: #6c7073;
  --gray-8: #15151b;
}
