.additionalInfo {
  background-color: var(--bg-light-gray);
  padding: 60px 0;
  @media (max-width: 991px) {
    padding: 30px 0 30px;
  }
  @media (max-width: 767px) {
    padding: 35px 0 28px;
  }
  &__title {
    margin-bottom: 42px;
    text-align: center;
    @media (max-width: 767px) {
      margin-bottom: 27px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 60px;
      position: relative;
      list-style: none;
      width: calc(100% / 3 - 40px);
      padding-left: 20px;
      line-height: 20px;
      font-size: 16px;
      font-family: var(--font-noah);
      @media (max-width: 992px) {
        width: calc(100% / 3 - 20px);
        margin-right: 30px;
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 11px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: var(--primary-black);
      }
      &:last-child {
        margin-right: 0;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }
}
.form-wrap {
  max-width: 555px;
  margin: 0 auto;
  padding-top: 40px;
  @media (max-width: 992px) {
    max-width: 100%;
  }
}
.form {
  &__line {
    margin-bottom: 16px;
    &:last-child {
      padding-top: 16px;
      margin-bottom: 24px;
    }
  }
}
