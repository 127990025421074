.button {
  background-color: var(--blue);
  border: none;
  outline: none;
  color: var(--white);
  font-size: 14px;
  line-height: 20px;
  font-family: var(--font-lexus-book);
  font-weight: 700;
  letter-spacing: 1px;
  padding: 12px 14px;
  min-width: 145px;
  border-radius: 4px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  &.disabled {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  svg {
    margin-left: 9px;
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 19px;
}
.lds-ellipsis div {
  position: absolute;
  top: 9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
