html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'noah-regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/noah-regular.otf') format('opentype');
}

@font-face {
  font-family: 'NobelWGL-Book';
  src: url('./assets/fonts/NobelWGL-Bold.eot');
  src: url('./assets/fonts/NobelWGL-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/NobelWGL-Bold.woff2') format('woff2'),
    url('./assets/fonts/NobelWGL-Bold.woff') format('woff'),
    url('./assets/fonts/NobelWGL-Bold.ttf') format('truetype'),
    url('./assets/fonts/NobelWGL-Bold.svg#NobelWGL-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NobelWGL-Book';
  src: url('./assets/fonts/NobelWGL-Book.eot');
  src: url('./assets/fonts/NobelWGL-Book.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/NobelWGL-Book.woff2') format('woff2'),
    url('./assets/fonts/NobelWGL-Book.woff') format('woff'),
    url('./assets/fonts/NobelWGL-Book.ttf') format('truetype'),
    url('./assets/fonts/NobelWGL-Book.svg#NobelWGL-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  height: auto!important;
}

body {
  margin: 0;
  font-family: 'NobelWGL-Book';
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}
#root {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.tabs__panel {
  width: 100%;
}
.MuiBox-root {
  width: 100%;
  max-width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px 0 !important;
}
.ShowMoreContainer .MuiBox-root {
  padding-bottom: 0 !important;
}
.MuiBox-root > div {
  /* width: 100%; */
}
.MuiBox-root .scroller {
  width: auto;
}
.MuiTabs-flexContainer > button > span {
  border-bottom: 1px solid #e4e4e4;
}

.MuiButtonBase-root svg {
  margin-bottom: 6px;
}

.MuiTabs-indicator {
  background-color: #b12a31 !important;
}

.MuiModal-root .MuiBox-root {
  padding: 0 !important;
}
.MuiTab-iconWrapper {
  max-width: 40px;
  max-height: 40px;
}
