.inputWrap {
  border: 1px solid var(--input-border-color);
  padding: 16px 20px;
  position: relative;
  display: flex;
  z-index: 0;
  height: 56px;
  width: 100%;
  &.error {
    border-color: var(--primary-red);
  }
}
.label {
  font-size: 18px;
  line-height: 30px;
  color: var(--primary-black);
  position: absolute;
  transition: all 0.3s;
  top: 17px;
  z-index: 1;
  display: block;
  line-height: normal;
  &.focus {
    top: 7px;
    left: 20px;
    font-size: 10px;
  }
}
.input {
  border: none;
  outline: none;
  font-size: 16px;
  line-height: normal;
  color: var(--gray-7);
  position: absolute;
  left: 20px;
  top: calc(50% - 25%);
  z-index: 1;
  display: block;
  width: calc(100% - 80px);
  background: transparent;
  &.focus {
    top: 38%;
  }
}
.required {
  color: var(--primary-red);
  margin-left: 3px;
}
.errorName {
  margin-top: 8px;
  z-index: 10;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: var(--primary-red);
  font-family: var(--font-lexus-book);
}
.disabled {
  .inputWrap {
    background-color: var(--gray-4);
  }
}
