.searchInput {
  width: 100%;
  position: relative;
  .icon {
    width: 17px;
    height: 19px;
    position: absolute;
    left: 0;
    top: calc(50% - 9px);
  }
  &__input {
    width: 100%;
    border: none;
    outline: none;
    padding-left: 25px;
    padding-right: 25px;
    color: var(--primary-black);
    height: 46px;
    line-height: 24px;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  &__result {
    display: none;
    position: absolute;
    z-index: 10;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    width: 100%;
    max-height: 410px;
    overflow-y: auto;
    &.show {
      display: block;
    }
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--white);
      margin-top: 25px;
      margin-bottom: 25px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1a1a1f;
    }
  }
  &__result-item {
    cursor: pointer;
    border-bottom: 1px solid #e4e4e4;
    padding: 15px 16px 12px 42px;
    display: flex;
    flex-direction: column;
    position: relative;
    span {
      line-height: normal;
      &:nth-of-type(1) {
        color: var(--primary-black);
        font-size: 18px;
        margin-bottom: 7px;
      }
      &:nth-of-type(2) {
        color: var(--gray-8);
        font-size: 12px;
        letter-spacing: 0.5px;
      }
    }
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #f4f6fb;
    }
    &.active {
      background-color: #f4f6fb;
    }
  }
  &__result-item-icon {
    position: absolute;
    top: 19px;
    left: 16px;
  }
  .allResultBtn {
    padding: 12px 16px;
    font-size: 16px;
    line-height: 24px;
  }
}
.delete-icon-wrap {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  height: 18px;
  width: 18px;
  .delete-icon {
    width: 100%;
    height: 100%;
  }
}

.dealerList {
  // display: none;
  top: 85px;
  left: -24px;
  position: absolute;
  z-index: 11;
  background-color: #fff;
  width: 100%;
  width: 375px;
  max-height: 410px;
  overflow-y: auto;
  @media (max-width: 768px) {
    top: 82px;
  }
  @media (max-width: 567px) {
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    width: 100vw;
    max-width: 100%;
  }
  &.show {
    display: block;
  }
  &::-webkit-scrollbar {
    width: 2px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--white);
    margin-top: 25px;
    margin-bottom: 25px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #1a1a1f;
  }
  .dealer {
    display: flex;
    padding-left: 0;
    font-family: var(--font-lexus-book);
    margin-bottom: 18px;
    &__number {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      // border: 1px solid var(--gray-4);
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1a1a1f;
      color: #fff;
      margin-right: 18px;
      font-weight: 700;
      font-family: var(--font-lexus-book);
      font-size: 14px;
    }
    &__distance {
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-7);
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }
    &__name {
      margin-bottom: 16px;
      font-family: var(--font-lexus-book);
      color: var(--primary-black);
      font-size: 18px;
      // line-height: 24px;
      letter-spacing: 1px;
    }
  }
  &__item {
    padding: 24px;
    padding-right: 22px;
    margin-bottom: 19px;
    border-bottom: 1px solid #e4e4e4;
    &:last-child {
      border-bottom: none;
    }
  }
  &__item-row {
    margin-bottom: 16px;
    padding-left: 48px;
    &.btnContainer {
      margin-bottom: 19px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 14px 15px;
        width: 100%;
        border-radius: 3px;
        letter-spacing: 1px;
        font-weight: 700;
        font-family: var(--font-lexus-book);
      }
    }
  }
  &__contacts {
    display: block;
  }
  &__contacts-title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: #1a1a1f;
    font-family: var(--font-lexus-book);
    font-weight: 700;
    letter-spacing: 1px;
  }
  &__contacts-line {
    margin-bottom: 16px;
    span {
      &:nth-of-type(1) {
        margin-bottom: 5px;
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #4d5e7d;
        margin-bottom: 4px;
      }
      &:nth-of-type(2) {
        display: block;
        font-size: 18px;
        line-height: 30px;
        color: #1a1a1f;
        font-family: var(--font-lexus-book);
        a {
          color: #1a1a1f;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.error {
  position: absolute;
  left: 0;
  bottom: -28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &__text {
    font-size: 13px;
    color: #ff0022;
  }
}
