.errorPage {
  background-color: #383838;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    background-color: #15151b;
    width: 534px;
    height: 534px;
    left: 50%;
    bottom: -340px;
    transform: translateX(-50%);
    z-index: 0;
    @media (max-width: 768px) {
      left: 455px;
      bottom: -85px;
      transform: none;
    }
    @media (max-width: 567px) {
      width: 242px;
      height: 242px;
      left: 200px;
      bottom: -12%;
      transform: none;
    }
  }
  &::before {
    content: "";
    width: 896px;
    height: 896px;
    position: absolute;
    border-radius: 50%;
    background-color: #15151b;
    left: -477px;
    top: -280px;
    z-index: 0;
    @media (max-width: 768px) {
      left: -477px;
    }
    @media (max-width: 567px) {
      width: 406px;
      height: 406px;
      left: -66%;
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
  }
  &__title {
    font-size: 80px;
    line-height: 114px;
    font-family: var(--font-lexus-book);
    color: var(--white);
    padding-bottom: 24px;
    margin-bottom: 24px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 79px;
      height: 3px;
      background-color: var(--primary-red);
    }
  }
  &__sub-title {
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
    margin-bottom: 24px;
  }
  &__btn {
    width: 136px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    &:hover {
      cursor: pointer;
    }
  }
}
