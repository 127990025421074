.selectWrap {
  border: 1px solid var(--input-border-color);
  position: relative;
  z-index: 5;
  &.error {
    border-color: var(--primary-red);
  }
}
.required {
  color: var(--primary-red);
}
.errorName {
  margin-top: 8px;
  z-index: 10;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: var(--primary-red);
  font-family: var(--font-lexus-book);
}
.disabled {
  .inputWrap {
    background-color: var(--gray-4);
  }
}
.label {
  font-size: 18px;
  line-height: 30px;
  color: var(--primary-black);
  position: absolute;
  transition: all 0.3s;
  top: 18px;
  left: 20px;
  z-index: 1;
  display: block;
  line-height: normal;
  font-family: var(--font-lexus-book);
  &.focus {
    top: 7px;
    left: 20px;
    font-size: 10px;
  }
}
