.serviceInfo {
  max-width: 555px;
  margin: 0 auto;
  padding-top: 15px;
  @media (max-width: 992px) {
    max-width: 100%;
  }
  &__block {
    border-bottom: 1px solid #e4e4e4;
    padding: 24px 0 31px;

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      @media (max-width: 567px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .text {
        font-size: 14px;
        line-height: 20px;
        font-family: var(--font-lexus-book);
        font-weight: 700;
        color: var(--primary-black);
        text-transform: uppercase;
        @media (max-width: 767px) {
          margin-bottom: 12px;
        }
      }
      .editBtn {
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        font-family: var(--font-lexus-book);
        font-weight: 700;
        color: var(--blue);
        display: flex;
        align-items: center;
        svg {
          margin-left: 4px;
        }
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      &.active {
        margin-bottom: 0;
      }
    }
    &-sub-title {
      font-size: 13px;
      color: var(--gray-7);
      p {
        font-size: 13px;
        color: var(--gray-7);
      }
    }
    &.serviceList {
      padding-top: 31px;
      padding-bottom: 36px;
      .serviceInfo__block-title {
        margin-bottom: 30px;
      }
    }
    // &.dealerInfo {
    //   padding-top: 31px;
    // }
  }
  &__list {
  }
  &__list-item {
    margin-bottom: 12px;
    color: var(--primary-black);
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 4px;
      color: #6c6f76;
      letter-spacing: 0.5px;
    }
    .value {
      font-size: 18px;
      line-height: 30px;
      color: #1a1a1f;
    }
  }
  &__disclamer {
    color: #6c6f76;
    font-size: 12px;
    margin-top: 8px;
    line-height: 18px;
  }
}
.additionalServices {
  padding-top: 31px;
  .text {
    margin-bottom: 0;
  }
  .serviceInfo__block-title {
    flex-direction: row;
    cursor: pointer;
    margin-bottom: 28px;
    justify-content: flex-start;
    &.active {
      margin-bottom: 0;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 0;
    overflow: hidden;
    &.active {
      height: 100%;
      max-height: 500px;
    }
  }
  &__list-item {
    width: calc(50% - 24px);
    padding-right: 24px;
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &:nth-child(2n) {
      width: 50%;
      padding-right: 0;
      @media (max-width: 567px) {
        width: 100%;
      }
    }
    @media (max-width: 567px) {
      width: 100% !important;
    }
  }
}

.showMore {
  margin-left: 5px;
  padding-bottom: 3px;
  &.active {
    svg {
      transform: rotate(-180deg);
    }
  }
}
.clienInfo {
  padding-top: 32px;
  padding-bottom: 15px;
  &__form-line {
    margin-bottom: 16px;
  }
}
.priceInfo {
  padding-top: 29px;
  padding-bottom: 0;
  .serviceInfo__block-title {
    margin-bottom: 15px;
  }
  .text {
    text-transform: none;
    font-family: var(--font-lexus-book);
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }
  .price {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
    font-family: var(--font-lexus-book);
  }
}
.form {
  &__footer {
    padding: 28px 0 80px;
    @media (max-width: 767px) {
    }
  }
  &__footer-line {
    margin-bottom: 16px;
    &:last-child {
      padding-top: 12px;
      margin-bottom: 0;
    }
  }
}
.modal {
  @media (max-width: 767px) {
    padding-top: 26px;
  }
  &.successful {
    text-align: center;
  }
  &.privacy {
  }
  &__title {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 24px;
    text-transform: uppercase;
    font-family: var(--font-lexus-book);
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
    }
  }
  &__sub-title {
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 767px) {
      line-height: 20px;
    }
  }
}
.privacy {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: var(--white);
  overflow-y: auto;
  font-family: var(--font-lexus-book);
  h1 {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 25px;
    font-family: var(--font-toyota-semibold);
    @media (max-width: 767px) {
      font-size: 28px;
    }
  }
  h2 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 25px;
  }
  ul {
    padding-left: 16px;
    margin-bottom: 25px;
    li {
      font-size: 16px;
      line-height: 24px;
      position: relative;
      margin-bottom: 8px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: -16px;
        top: 11px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--primary-red);
      }
    }
  }
  ol {
    margin-bottom: 25px;
    counter-reset: item;
    li {
      font-size: 16px;
      line-height: 24px;
      position: relative;
      margin-bottom: 8px;
      list-style-type: 1;
      counter-increment: step-counter;
      &::before {
        content: counter(step-counter) '. ';
        color: var(--primary-red);
        font-weight: 700;
      }
    }
  }
  strong {
    font-family: var(--font-toyota-semibold);
  }

  /* width */
  &::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--white);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #1a1a1f;
  }

  /* width */
  &::-moz-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-moz-scrollbar-track {
    background: var(--white);
  }

  /* Handle */
  &::-moz-scrollbar-thumb {
    background: #1a1a1f;
  }
  &__text {
  }
}
